import(/* webpackMode: "eager" */ "D:\\programming\\next\\omniwink\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "D:\\programming\\next\\omniwink\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager", webpackExports: ["AiGlobe"] */ "D:\\programming\\next\\omniwink\\src\\components\\home\\AiGlobe.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\programming\\next\\omniwink\\src\\components\\home\\Faqs.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\programming\\next\\omniwink\\src\\components\\home\\Hero.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\programming\\next\\omniwink\\src\\components\\home\\Industry.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\programming\\next\\omniwink\\src\\components\\home\\Services.js");
;
import(/* webpackMode: "eager", webpackExports: ["AnimatedTestimonials"] */ "D:\\programming\\next\\omniwink\\src\\components\\ui\\animated-testimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MacbookScroll"] */ "D:\\programming\\next\\omniwink\\src\\components\\ui\\mackbook-scrool.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Timeline"] */ "D:\\programming\\next\\omniwink\\src\\components\\ui\\timeline.tsx");
