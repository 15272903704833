"use client"
import { Button } from 'flowbite-react'
import React from 'react'
import { motion } from 'framer-motion'
import Image from 'next/image';
import { IconArrowRight, IconCalendarCheck } from '@tabler/icons-react';
import Link from 'next/link';
const Hero = () => {


  return (
    <>
      <section className='container mx-auto pt-20 lg:pt-0 pb-10 '>
        <div className="grid grid-cols-1 lg:grid-cols-2  flex-1 gap-4 px-5  text-black">
          <div className='py-auto my-auto text-center lg:text-left px-0 lg:px-10'>
            <span className='bg-black text-white rounded-full border-black border-1 px-3 py-1'>Let AI handle your problems</span>
            <h1 className='text-5xl font-bold mt-5  bg-gradient-to-r from-neutral-900 via-[rgba(30,63,102,1)] to-[rgba(30,63,102,1)] bg-clip-text text-transparent'>Automate Your Business on Steroids</h1>
            <p className='pt-5 lg:text-justify text-lg'>
              Take your business to the next level with fully automated workflows, lead management, pipelines, and streamlined communication tools and crush the competition.
            </p>
            <div className='flex flex-col md:flex-row gap-3 pt-5 justify-center lg:justify-start'>
              <Button as={Link} className='bg-black hover:bg-slate-800 rounded-full text-md py-2 px-3 md:text-xl' href="/pricing">Get Started Now <IconArrowRight className='ml-2' /></Button>
              <Button as={Link} className='bg-black rounded-full hover:bg-slate-800 text-md py-2 px-3 md:text-xl' href="/contact-us#booking-demo">Request A Demo <IconCalendarCheck className='ml-2' /></Button>
            </div>
          </div>
          <div className='my-auto pt-10'>
            <motion.div
              className=' relative min-h-[400px] md:min-h-[700px]'
              animate={{
                y: [0, 7, 0]
              }}
              transition={{
                duration: 1, // Duration of animation
                ease: "easeOut", // Smooth easing
                repeat: Infinity, // Loop infinitely
                repeatType: "loop", // Continuous loop
              }}
            >
              <Image src={'/header.gif'} fetchPriority='high' priority className='object-contain' fill alt="Single Inbox for all Social platforms, SMS and Emails - Omniwink" />
            </motion.div>
          </div>
        </div>
      </section>

    </>
  )
}



export default Hero