"use client";
import React from "react";
import { StickyScroll } from "../ui/sticky-scroll-reveal";
import Image from "next/image";
import { ReactLenis } from 'lenis/react';
import { motion } from 'framer-motion'
const content = [
    {
        title: "Centralized Communication Hub",
        description:
            "All your customer conversations in one place, finally. Omniwink gathers SMS, email, Facebook DMs, Instagram DMs, and Google My Business messages into a single, centralized hub.",
        content: (
            <Image
                src="/solution/1.png"
                width={350}
                height={350}
                className="object-cover block mx-auto"
                alt="linear board demo"
            />
        ),
    },
    {
        title: "Comprehensive Sales Pipelines",
        description:
            "Lead, follow, close, repeat. Track every lead from first contact to final sale, ensuring no lead falls through the cracks. With full visibility, you’ll stay in control and improve your conversion rates like never before. Every step is optimized for success.",
        content: (
            <div className="h-full w-full  flex items-center justify-center text-white">
                <Image
                    src="/solution/2.png"
                    width={350}
                    height={350}
                    className=" object-cover block mx-auto"
                    alt="linear board demo"
                />
            </div>
        ),
    },
    {
        title: "Powerful Automation",
        description:
            "Automate your workflows to nurture leads, send follow-ups, and even schedule phone calls, all without lifting a finger. With 24/7 automation, your business runs even while you sleep, giving you more time to focus on strategy and growth.",
        content: (
            <div className="h-full w-full  flex items-center justify-center text-white">
                <Image
                    src="/solution/3.png"
                    width={350}
                    height={350}
                    className="object-cover block mx-auto"
                    alt="linear board demo"
                />
            </div>
        ),
    },
    {
        title: "Advanced Marketing Tools",
        description:
            "Take your marketing to the next level. With Omniwink`s drag and drop email editor, you can create stunning campaigns in minutes. Schedule and post across social media platforms like Facebook, Instagram, LinkedIn, and Google Business, all from one platform. Moreover, with integrated SMS marketing, you can reach customers directly on their phones. It’s marketing, redefined for efficiency.",
        content: (
            <div className="h-full w-full  flex items-center justify-center text-white">
                <Image
                    src="/solution/4.png"
                    width={350}
                    height={350}
                    className="object-cover block mx-auto"
                    alt="linear board demo"
                />
            </div>
        ),

    },
    {
        title: "Customizeables Websites and Funnels",
        description:
            "Launch a website or funnel that actually converts. With Omniwink’s easy to use builder, you can create high converting websites and sales funnels in minutes, no coding required. Your online presence will look sleek, professional, and optimized for sales. Plus, free hosting means you can start fast and save on additional costs.",
        content: (
            <div className="h-full w-full  flex items-center justify-center text-white">
                <Image
                    src="/solution/5.png"
                    width={350}
                    height={350}
                    className="object-cover block mx-auto"
                    alt="linear board demo"
                />
            </div>
        ),
    },
    {
        title: "Efficient Calendar Management",
        description:
            "Time is your most valuable asset, and Omniwink helps you make the most of it. Manage your appointments, meetings, and service schedules with our advanced calendar system, ensuring you and your team stay organized and productive. Keep your team productive and your customers happy.",
        content: (
            <div className="h-full w-full  flex items-center justify-center text-white">
                <Image
                    src="/solution/6.png"
                    width={350}
                    height={350}
                    className="object-cover block mx-auto"
                    alt="linear board demo"
                />
            </div>
        ),
    },
    {
        title: "E-Surveys and Forms",
        description:
            "Gather valuable information from your customers with Omniwink’s customizable surveys and forms. Whether you’re collecting feedback or generating leads, our tools make it easy to embed forms on your website and capture critical data to improve your services. Insight leads to action, and action leads to growth.",
        content: (
            <div className="h-full w-full  flex items-center justify-center text-white">
                <Image
                    src="/solution/7.png"
                    width={350}
                    height={350}
                    className="object-cover block mx-auto"
                    alt="linear board demo"
                />
            </div>
        ),
    },
    {
        title: "Review Management",
        description:
            "Your reputation can make or break your business. Omniwink helps you stay on top of your online reviews, allowing you to manage, respond, and request reviews from satisfied customers, all from one place. Build trust, attract new customers, and increase your sales with a strong online presence that speaks for itself.",
        content: (
            <div className="h-full w-full  flex items-center justify-center text-white">
                <Image
                    src="/solution/8.png"
                    width={350}
                    height={350}
                    className="object-cover block mx-auto"
                    alt="linear board demo"
                />
            </div>
        ),
    },

];
export default function Services() {
    const transitionValues = {
        duration: 0.8,
        yoyo: Infinity,
        ease: "easeOut"
    };
    const ballStyle = {
        display: "block",
        width: "5rem",
        height: "5rem",
        backgroundColor: "black",
        borderRadius: "5rem",
        marginRight: "auto",
        marginLeft: "auto"
    };
    return (
        <div className="py-32">

            {/* <StickyScroll content={content} /> */}
            <ReactLenis root>
                <main>
                    <article>
                        <section className='text-black  h-screen  w-full   grid place-content-center sticky top-0 overflow-hidden'>

                            <div className="2xl:text-7xl text-6xl pb-5 text-center leading-relaxed mb-10 font-semibold bg-gradient-to-r from-neutral-900 via-[rgba(30,63,102,1)] to-[rgba(30,63,102,1)] bg-clip-text text-transparent">Benefits Of Using This System</div>
                            <div className="2xl:text-7xl text-6xl pb-5 text-center leading-relaxed mb-10 font-semibold">
                                <motion.div

                                    animate={{
                                        y: [0, 30, 0], // Y-axis values for bounce
                                    }}
                                    transition={{
                                        duration: 0.6,  // Duration of one bounce
                                        ease: "easeInOut",  // Easing function for smooth animation
                                        repeat: Infinity,  // Loop the animation
                                        repeatType: "loop", // Infinite loop
                                    }}
                                >
                                    👇
                                </motion.div>
                            </div>

                        </section>

                        {content.map((c, i) => {
                            return (
                                <section key={i} className='h-screen  w-full bg-black text-white  grid place-content-center sticky top-0 overflow-hidden'>

                                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-32">
                                        <div className="my-auto">
                                            <div className='2xl:text-6xl text-5xl px-8 font-semibold text-center tracking-tight leading-[120%]'>
                                                {c.title}
                                            </div>
                                            <p className='text-xl px-5 md:px-10 lg:px-20 mt-8 text-center'>{c.description}</p>
                                        </div>
                                        <div className="pt-10 lg:pt-0">
                                            <motion.div

                                                animate={{
                                                    y: [0, 8, 0], // Y-axis values for bounce
                                                }}
                                                transition={{
                                                    duration: 0.9,  // Duration of one bounce
                                                    ease: "easeInOut",  // Easing function for smooth animation
                                                    repeat: Infinity,  // Loop the animation
                                                    repeatType: "loop", // Infinite loop
                                                }}
                                            >
                                                {c.content}
                                            </motion.div>
                                        </div>
                                    </div>
                                </section>
                            )
                        })}


                    </article>
                </main>
            </ReactLenis>
        </div>
    );
}