"use client";

import { Accordion } from "flowbite-react";
import faqData from '@/data/faqData.json'
export default function Faqs() {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 px-2 md:px-10 my-20">
            <div className="col-span-1 pr-0 lg:pr-20 my-auto pb-10">
                <div className="text-5xl font-bold mb-5">FAQs</div>
                <p className="text-lg">
                    Get the answers you need about our AI solutions, implementation strategies, and support options. Our FAQ section is crafted to show you how Omniwink can fulfill your unique needs.
                </p>
            </div>
            <div className="col-span-2">
                <Accordion collapseAll>
                    {faqData.map(f => {
                        return (
                            <Accordion.Panel key={f.id}>
                                <Accordion.Title className="bg-black my-2 hover:bg-black text-white text-xl font-bold">{f.question}</Accordion.Title>
                                <Accordion.Content className="bg-neutral-100 text-lg">
                                    <p>{f.answer}</p>
                                </Accordion.Content>
                            </Accordion.Panel>

                        );
                    })}
                </Accordion>
            </div>
        </div>
    );
}