 "use client"
import { useEffect } from "react";
import { motion } from 'framer-motion'
// HorizontalScrollSection is a component that will allow the user to scroll the children horizontally when the user scrolls down
const Industry = ({ children }) => {
  useEffect(() => {
    const horizontalScrollElements = [
      ...document.querySelectorAll(".horizontal-scroll-element"),
    ];

    // Apply initial height adjustments
    for (let i = 0; i < horizontalScrollElements.length; i++) {
      const sectionWidth = horizontalScrollElements[i].getElementsByClassName(
        "horizontal-scroll-item"
      )[0].offsetWidth;

      horizontalScrollElements[i].parentElement.style.height = `${window.innerHeight + sectionWidth - window.innerWidth
        }px`;
    }

    function transform(section) {
      const offsetTop = section.parentElement.offsetTop;
      const sectionWidth = section.getElementsByClassName(
        "horizontal-scroll-item"
      )[0].offsetWidth;
      const scrollSection = section.querySelector(".horizontal-scroll-section");

      let amount = window.scrollY - offsetTop;
      amount = amount < 0 ? 0 : amount;
      if (amount < sectionWidth - window.innerWidth) {
        scrollSection.style.transform = `translate3d(-${amount}px, 0, 0)`;
      }
    }

    // Smooth scroll animation effect using requestAnimationFrame
    let scrollTimeout;
    function smoothScroll() {
      // Cancel previous animation frame if a new one is requested
      if (scrollTimeout) {
        cancelAnimationFrame(scrollTimeout);
      }

      // Apply the transform function for each horizontal scroll element
      for (let i = 0; i < horizontalScrollElements.length; i++) {
        transform(horizontalScrollElements[i]);
      }

      // Request the next frame for smooth scrolling
      scrollTimeout = requestAnimationFrame(smoothScroll);
    }

    // Start listening to the scroll event and trigger the smooth scroll animation
    window.addEventListener("scroll", smoothScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", smoothScroll);
      if (scrollTimeout) {
        cancelAnimationFrame(scrollTimeout);
      }
    };
  }, []);
  return (
    <div className="horizontal-scroll-container hidden md:block">
      <div className="horizontal-scroll-element overflow-hidden sticky top-0 h-screen ">
        <div className="horizontal-scroll-section will-change-transform flex justify-between items-center ">
          <div className="horizontal-scroll-item flex flex-shrink-0 h-screeen mt-10">
            <div className="flex justify-between items-center w-screen h-screen text-center px-40">
              <div className="">
                <div className="2xl:text-7xl text-6xl border-black pb-3 font-bold">
                  <span className="bg-gradient-to-r from-neutral-900 via-[rgba(30,63,102,1)] to-[rgba(30,63,102,1)] bg-clip-text text-transparent">
                    Industries We Serve
                  </span>{" "}
                  <span className="relative bottom-3">
                    <motion.span
                      className="inline-block"
                    animate={{
                      x: [0, 40, 0], // Y-axis values for bounce
                    }}
                    transition={{
                      duration: 0.6,  // Duration of one bounce
                      ease: "easeInOut",  // Easing function for smooth animation
                      repeat: Infinity,  // Loop the animation
                      repeatType: "loop", // Infinite loop
                    }}
                    > 👉
                  </motion.span>
                </span>
              </div>
              <p className="mt-10 pr-5 text-xl">
                Omniwink delivers AI-powered solutions across a wide range of
                service industries, each carefully tailored to enhance
                productivity, client engagement, and operational success.
              </p>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
    </div >
  );
};

export default Industry;
